import React from "react";
import Loader from "react-loader-spinner";

class SpinnerComponent extends React.Component {
    state = {
        loading: true,
    };
    render() {
        return (
            <div className="sweet-loading">
                <Loader
                    type="ThreeDots"
                    color={"#FF586B"}
                />
            </div>
        );
    }
}

export default SpinnerComponent;
